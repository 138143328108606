import { PERMISSION_ACTION, PERMISSION_SUBJECT } from '@finance-ops/types';
import { Badge, Box, MenuItem, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetURLParams } from '../@core/utils';
import CanWrapper from '../layouts/components/acl/CanWrapper';
import { RootState } from '../store';
import { setSelectedClientId } from '../store/client';
import { Track } from '../@core/track';
import { useClients } from '../hooks/useClients';

const ClientList = ({ getClientUnreadCount }: { getClientUnreadCount?: (clientId: string) => number }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: clients } = useClients();
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);
  const defaultValue = { label: 'All', value: null };
  const [selectedClient, setSelectedClient] = useState<{ [key: string]: any }>(defaultValue);
  const [inputValue, setInputValue] = useState('');

  // Memoize the options to prevent unnecessary recalculations
  const options = useMemo(() => {
    return [
      defaultValue,
      ...(clients?.filter(client => client.isActive).map(client => ({ label: client.name, value: client._id })) || []),
    ];
  }, [clients]);

  useEffect(() => {
    if (selectedClientId && clients?.length) {
      const selectedClient = clients.find(client => client._id === selectedClientId);
      if (selectedClient) {
        setSelectedClient({ label: selectedClient.name, value: selectedClient._id });
      }
    }
  }, [selectedClientId, clients]);

  const ItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.MuiMenuItem-root': {
      borderRadius: 0,
      paddingTop: '2px',
      paddingBottom: '2px',
      fontWeight: '500',
    },
    '&.MuiMenuItem-root:hover': {
      borderRadius: '18px',
      backgroundColor: theme.palette.primary.light,
    },
  };

  const handleNewClient = (newValue: { [key: string]: any }) => {
    setSelectedClient(newValue);
    dispatch(setSelectedClientId(newValue.value));
    resetURLParams(window);
  };

  useEffect(() => {
    if (selectedClientId && clients && clients.length > 0) {
      const selectedClient = clients.find(client => client._id === selectedClientId);
      if (selectedClient) {
        Track.getInstance().appliedClientFilter({
          clientId: selectedClient._id,
          clientName: selectedClient.name,
        });
      }
    }
  }, [selectedClientId, clients]);

  return (
    <CanWrapper permission={{ actions: [PERMISSION_ACTION.READ], subject: PERMISSION_SUBJECT.SELECT_CLIENT_ID }}>
      <Autocomplete
        options={options}
        getOptionLabel={option => option.label}
        clearIcon={false}
        renderInput={params => {
          const unreadCount = (getClientUnreadCount && getClientUnreadCount(selectedClient.value)) || null;
          return (
            <TextField
              className='client-list-text-field'
              variant='outlined'
              sx={{
                width: { xs: '100%', sm: '200px' },
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {unreadCount ? (
                      <Badge
                        badgeContent={unreadCount}
                        color='error'
                        sx={{ ml: '10px', fontSize: '12px', fontWeight: 700 }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, item) => {
          const unreadCount = (getClientUnreadCount && getClientUnreadCount(item.value)) || null;
          return (
            <MenuItem
              sx={{
                ...ItemStyle,
                ...(selectedClient.value === item.value && {
                  borderRadius: '18px !important',
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.main,
                }),
              }}
              {...props}
            >
              <Box
                sx={{
                  color: 'black',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flex: 1,
                  mr: 2, // Add margin to ensure space for badge
                }}
              >
                {item.label}
              </Box>
              <Box
                sx={{
                  minWidth: '30px',
                  position: 'relative',
                  top: '-1px',
                  flexShrink: 0, // Prevent badge from shrinking
                  '& .MuiBadge-badge': {
                    background: theme.palette.error.main,
                    color: theme.palette.common.white,
                    right: '-20px',
                    minWidth: '1rem',
                    minHeight: '1rem',
                  },
                }}
              >
                {unreadCount ? <Badge badgeContent={unreadCount} /> : null}
              </Box>
            </MenuItem>
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          handleNewClient(newValue || defaultValue);
        }}
        inputValue={inputValue}
        value={selectedClient}
        sx={{
          ml: 4,
          '.MuiInputBase-root': {
            py: 0,
          },
        }}
      />
    </CanWrapper>
  );
};

export default ClientList;
