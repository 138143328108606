import { addDays, differenceInDays, startOfMonth, startOfQuarter, startOfYear, subDays } from 'date-fns';
import { Button, Menu, MenuItem, Box, Typography, Popover, useTheme } from '@mui/material';
import { formatDateToMonthShort } from 'apps/webapp/src/@core/utils/format';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Dispatch, SetStateAction, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { DownSamplingTimeFrame } from '@finance-ops/types';
import useLocalStorageDashboardState from 'apps/webapp/src/hooks/dashboard';

interface DateRanges {
  main: [Date | null, Date | null];
  compare?: [Date | null, Date | null];
}

interface CustomDatePickerProps {
  showComparison?: boolean;
  setDateRanges: Dispatch<SetStateAction<DateRanges>>;
  dateRanges: DateRanges;
  selectedRange?: string;
  downSamplingSize: DownSamplingTimeFrame;
  setDownSamplingSize: Dispatch<SetStateAction<DownSamplingTimeFrame>>;
  showDownSampling?: boolean;
}

const options = [
  'Today',
  'Last 7 days',
  'Last 4 weeks',
  'Last 3 months',
  'Last 12 months',
  'Month to date',
  'Quarter to date',
  'Year to date',
  'All time',
  'Custom',
];
const compareOptions = ['Previous period', 'Previous year', 'Custom', 'No comparison'];

const CustomDatePicker = ({
  showComparison = true,
  setDateRanges,
  dateRanges = { main: [new Date(), new Date()], compare: [new Date(), new Date()] },
  selectedRange,
  downSamplingSize,
  setDownSamplingSize,
  showDownSampling = true,
}: CustomDatePickerProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorDownsampling, setAnchorDownsampling] = useState<null | HTMLElement>(null);
  const [compareAnchorEl, setCompareAnchorEl] = useState<null | HTMLElement>(null);
  const { getLocalStorageDashboardState, setLocalStorageDashboardState } = useLocalStorageDashboardState();
  const [selectedDateRange, setSelectedDateRange] = useState<string>(() => {
    const localStorageDashboardState = getLocalStorageDashboardState();
    if (localStorageDashboardState.selectedDateRange) {
      return localStorageDashboardState.selectedDateRange;
    }
    return selectedRange ?? options[0];
  });
  const [selectedComparison, setSelectedComparison] = useState<string>(compareOptions[0]);

  const [dateRangePickerAnchorEl, setDateRangePickerAnchorEl] = useState<null | HTMLElement>(null);
  const [comparisonDatePickerAnchorEl, setComparisonDatePickerAnchorEl] = useState<null | HTMLElement>(null);

  const calculateDates = (range: string, cmp = new Date(), compareOption?: string): [Date, Date] => {
    const today = cmp ? cmp : new Date();
    switch (range) {
      case 'Today':
        return [today, today];
      case 'Last 7 days':
        return [addDays(today, -6), today];
      case 'Last 4 weeks':
        return [addDays(today, -27), today];
      case 'Last 3 months':
        return [addDays(today, -90), today];
      case 'Last 12 months':
        return [addDays(today, -364), today];
      case 'Month to date':
        return [startOfMonth(today), today];
      case 'Quarter to date':
        return [startOfQuarter(today), today];
      case 'Year to date':
        return [startOfYear(today), today];
      case 'Previous period':
        return calculateDates(compareOption ?? 'Last 7 days', addDays(cmp, -1));
      case 'Previous year': {
        return [addDays(cmp, -365), subDays(cmp, 1)];
      }
      case 'All time':
        return [new Date('2022-01-01'), today];
      default:
        return [new Date(), new Date()];
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickDownsampling = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDownsampling(event.currentTarget);
  };

  const handleClose = (option?: string) => {
    if (option) {
      setSelectedDateRange(option);
      setLocalStorageDashboardState({ selectedDateRange: option });
      const main = calculateDates(option);
      setDateRanges({
        main: main,
        compare: calculateDates(selectedComparison, main[0], option),
      });
    }
    setAnchorEl(null);
    setDateRangePickerAnchorEl(option === 'Custom' ? anchorEl : null);
  };

  const handeCloseDownsampling = (option?: string) => {
    if (option) {
      setDownSamplingSize(option as DownSamplingTimeFrame);
    }
    setAnchorDownsampling(null);
  };

  const handleCompareClick = (event: React.MouseEvent<HTMLElement>) => {
    setCompareAnchorEl(event.currentTarget);
  };

  const handleCompareClose = (option?: string) => {
    if (option) {
      setSelectedComparison(option);
      setDateRanges(prev => ({
        ...prev,
        compare: calculateDates(option, dateRanges.main[0] ?? new Date(), selectedDateRange),
      }));
    }
    setCompareAnchorEl(null);
    setComparisonDatePickerAnchorEl(option === 'Custom' ? compareAnchorEl : null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box sx={{ border: `1px solid #888`, display: 'flex', alignItems: 'center', pr: '8px', borderRadius: '4px' }}>
        <Button
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          sx={{
            textTransform: 'none',
            p: '4px',
            color: theme.palette.text.primary,
            borderRight: `1px solid ${theme.palette.text.primary}`,
            borderRadius: '0',
            '& .MuiButton-icon': {
              marginLeft: '0px',
            },
          }}
        >
          {selectedDateRange}
        </Button>
        <Box sx={{ pl: '6px' }}>
          <Typography>{`${dateRanges.main[0] ? formatDateToMonthShort(dateRanges.main[0]) : ''} - ${
            dateRanges.main[1] ? formatDateToMonthShort(dateRanges.main[1]) : ''
          }`}</Typography>
        </Box>
      </Box>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose()}>
        {options.map((option, index) => (
          <MenuItem key={index} selected={option === selectedDateRange} onClick={() => handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      {showComparison && (
        <>
          <Typography>compared to</Typography>

          <Box sx={{ border: `1px solid #888`, display: 'flex', alignItems: 'center', pr: '8px', borderRadius: '4px' }}>
            <Button
              onClick={handleCompareClick}
              endIcon={<ArrowDropDownIcon />}
              sx={{
                textTransform: 'none',
                p: '4px',
                color: theme.palette.text.primary,
                borderRight: `1px solid ${theme.palette.text.primary}`,
                borderRadius: '0',
                '& .MuiButton-icon': {
                  marginLeft: '0px',
                },
              }}
            >
              {selectedComparison}
            </Button>
            {dateRanges.compare && (
              <Box sx={{ pl: '6px' }}>
                <Typography>{`${dateRanges.compare[0] ? formatDateToMonthShort(dateRanges.compare[0]) : ''} - ${
                  dateRanges.compare[1] ? formatDateToMonthShort(dateRanges.compare[1]) : ''
                }`}</Typography>
              </Box>
            )}
          </Box>
          <Menu anchorEl={compareAnchorEl} open={Boolean(compareAnchorEl)} onClose={() => handleCompareClose()}>
            {compareOptions.map((option, index) => (
              <MenuItem key={index} selected={option === selectedComparison} onClick={() => handleCompareClose(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}

      <Popover
        open={Boolean(dateRangePickerAnchorEl)}
        anchorEl={dateRangePickerAnchorEl}
        onClose={() => setDateRangePickerAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatePicker
          selected={dateRanges.main[0]}
          onChange={(dates: [Date | null, Date | null]) => {
            const start = dates[0] as Date;
            const end = dates[1];
            setDateRanges(prev => {
              const compareZero = subDays(start, 1);
              const diffDays = differenceInDays(end ?? start, start);
              const compareOne = subDays(compareZero, diffDays);
              console.log(compareOne, compareZero);
              setSelectedComparison(compareOptions[2]);
              return { main: [start, end], compare: [compareOne, compareZero] };
            });
          }}
          startDate={dateRanges.main[0]}
          endDate={dateRanges.main[1]}
          selectsRange
          inline
        />
      </Popover>
      <Popover
        open={Boolean(comparisonDatePickerAnchorEl)}
        anchorEl={comparisonDatePickerAnchorEl}
        onClose={() => setComparisonDatePickerAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {dateRanges.compare && (
          <DatePicker
            selected={dateRanges.compare[0]}
            onChange={(dates: [Date | null, Date | null]) => {
              const [start, end] = dates;
              setDateRanges(prev => ({ ...prev, compare: [start, end] }));
            }}
            startDate={dateRanges.compare[0]}
            endDate={dateRanges.compare[1]}
            selectsRange
            inline
          />
        )}
      </Popover>
      {showDownSampling && (
        <Box sx={{ border: `1px solid #888`, display: 'flex', alignItems: 'center', pr: '8px', borderRadius: '4px' }}>
          <Button
            onClick={handleClickDownsampling}
            endIcon={<ArrowDropDownIcon />}
            sx={{
              textTransform: 'none',
              p: '4px',
              color: theme.palette.text.primary,
              borderRadius: '0',
              '& .MuiButton-icon': {
                marginLeft: '0px',
              },
            }}
          >
            {downSamplingSize}
          </Button>
        </Box>
      )}
      <Menu anchorEl={anchorDownsampling} open={Boolean(anchorDownsampling)} onClose={() => handeCloseDownsampling()}>
        {Object.values(DownSamplingTimeFrame).map((option, index) => (
          <MenuItem key={index} selected={downSamplingSize === option} onClick={() => handeCloseDownsampling(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CustomDatePicker;
